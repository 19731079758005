:root {
	--grid-padding: 10vw;
	--grid-columns: 4;
}

#grid {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(var(--grid-columns), 1fr);
	grid-column-gap: var(--grid-padding);
	grid-row-gap: var(--grid-padding);
	padding-bottom: var(--grid-padding);;
}

.no-margin #grid {
	padding-bottom: 0;
}

#grid::before {
	content: '';
	width: 0;
	grid-row: 1 / 1;
	grid-column: 1 / 1;
}

#grid > *:first-child {
	grid-row: 1 / 1;
	grid-column: 1 / 1;
}

@media only screen and (max-width: 720px) {
	:root {
		--grid-padding: 15vw;
		--grid-columns: 3;
	}
}

@media only screen and (max-width: 540px) {
	:root {
		--grid-padding: 20vw;
		--grid-columns: 2;
	}
}