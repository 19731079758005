.about {
	width: 100vw;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	overflow-x: scroll;
	cursor: -webkit-grab;
}

.no-margin .about {
	border-top: none;
}

.about:active {
	cursor: -webkit-grabbing;
}

.about .wrap {
	padding: var(--page-padding);
	display: flex;
	flex: 1;
	flex-wrap: nowrap;
	flex-direction: row;
}

.column {
	flex-shrink: 0;
	width: fit-content;
	max-width: calc(var(--column-width) + var(--column-gap));
	padding-right: var(--column-gap);
}

.column ul {
	list-style-type: none;
}

.interviews ul li {
	margin-bottom: calc(var(--paragraph-size) * 1.6);
}