.project-image {
	height: 100%;
	margin-right: 8px;
	background-color: #fafafa;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
}

.project-image::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(0, 0, 0, 0.1) inset;
}

.project-image:last-child {
	margin-right: 0;
}

.project-image img {
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.8s ease;
}