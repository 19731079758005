.grid-item {
	position: relative;
	opacity: 1;
	transition: opacity 0.8s ease;
	padding-bottom: 100%;
}

.grid-item.hidden {
	opacity: 0;
}

.grid-item img {
	position: absolute;
	max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.8s ease;
	cursor: pointer;
}

.grid-item .number {
	position: absolute;
	bottom: -48px;
	line-height: 48px;
	text-align: center;
	width: 100%;
	font-size: var(--paragraph-size);
	color: #444;
	font-style: normal;
	pointer-events: none;
}

.grid-item .number span {
	margin-left: 0.1em;
}

.grid-item .active .number::after {
	content: '';
	width: 1.8em;
	height: 1.8em;
	border: 1px solid;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.grid-item .title {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-size: 32px;
	border: 2px solid #f2f2f2;
}

@media only screen and (max-width: 540px) {
	.grid-item .number {
		bottom: -8.8vw;
		line-height: 8.8vw;
	}
}