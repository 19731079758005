:root {
	--column-width: 360px;
	--column-gap: 60px;
	--title-size: 20px;
	--paragraph-size: 17px;
	--page-padding: 48px;
}

::-webkit-scrollbar {
	display: none;
}

.project-content-wrap {
	grid-column: 1 / -1;
	grid-row: auto;
	height: 100vh;
	position: relative;
	width: 100vw;
	overflow-x: scroll;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	cursor: -webkit-grab;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset, 0 1px 0 rgba(0, 0, 0, 0.1) inset;
}

.project-content-wrap:active {
	cursor: -webkit-grabbing;
}

.project-content {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 100%;
	width: 100%;
	position: relative;
}

.project-text-wrap {
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
	flex-grow: 0;
	padding-right: calc(var(--page-padding) * 2);
	box-sizing: content-box;
}

.project-content .title {
	column-span: all;
	z-index: 1;
	position: relative;
}

.project-content .content {
	z-index: 1;
	position: absolute;
	top: var(--page-padding);
	left: var(--page-padding);
	bottom: var(--page-padding);
	column-count: auto;
	column-width: var(--column-width);
	column-fill: auto;
	column-gap: var(--column-gap);
	width: var(--column-width);
}

.images {
	flex-shrink: 0;
	flex-grow: 0;
	display: flex;
}

.project-details-wrap {
	padding: var(--page-padding);
}

.project-details {
	width: var(--column-width);
	flex-shrink: 0;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.height-change-listener {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 1px;
	height: 100%;
	pointer-events: none;
	background-color: transparent;
	border: 0;
}

@media only screen and (max-width: 540px) {
	:root {
		--column-width: calc(66vw + ((540px - 100vw) / 6));
		--column-gap: 11vw;
		--title-size: 3.7vw;
		--paragraph-size: 3.14vw;
		--page-padding: calc(8.8vw - ((540px - 100vw) / 20));
	}
}

@media only screen and (max-width: 460px) {
	:root {
		--title-size: 17.02px;
		--paragraph-size: 14.44px;
	}
}