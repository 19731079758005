* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}

html{
	scroll-behavior: smooth;
}

body, html {
	width: 100%;
	height: 100%;
	position: fixed;
}

body {
	font-family: "Univers";
	font-weight: 400;
	letter-spacing: 0.06em;
	font-size: var(--paragraph-size);
	line-height: 1.6;
	color: #444;
	background-color: #fff;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	overflow-anchor: none;
}

#root {
	position: fixed;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	overflow-anchor: none;
	height: 100%;
	width: 100%;
}

h1, h2 {
	text-transform: uppercase;
	font-size: var(--paragraph-size);
	font-weight: normal;
	margin-bottom: calc(var(--paragraph-size) * 1.6);
}

a {
	color: inherit;
	text-decoration: none;
	font-style: italic;
}

a:hover {
	border-bottom: 1px solid;
}

p {
	widows: 2;
}

p:not(:last-child) {
	margin-bottom: calc(var(--paragraph-size) * 1.6);
}
