#spinner {
	position: absolute;
	top: var(--page-padding);
	left: var(--page-padding);
	line-height: 24px;
	border-radius: 50%;
	text-transform: uppercase;
}

#spinner.blink {
	animation: blinker 0.15s step-end infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}